












import { Component, Vue, Prop } from 'vue-property-decorator';
import Card from '@/components/card/Card.vue';
import UserTable from '../components/UserTable.vue';
import { UserService } from '../service/UserService';
import { UserInterface } from '../domain/UserInterface';
import Page from '@/components/Page.vue';
import ListingAddButton from '@/components/ListingAddButton.vue';

@Component({
    components: { Card, UserTable, Page, ListingAddButton},
})
export default class UserListing extends Vue {
    private users: UserInterface[] = [];
    private isLoading: boolean = false;

    private async mounted() {
       await this.fetchUsers();

    }

    private async deleteUser(user: UserInterface) {
      this.isLoading = true;
      try {
        await UserService.deleteKeycloakUser(user.id, this.$store.getters['sessionStore/token']);
        await UserService.delete(user.id, this.$store.getters['sessionStore/token']);
        await this.fetchUsers();
      } catch {
        this.$notify({
          title: 'Erreur',
          message: 'Erreur lors de la suppression de l\'utilisateur. Veuillez réessayer.',
        });
      }
      this.isLoading = false;
    }

    private async fetchUsers() {
      this.isLoading = true;
      try {
        const users = await UserService
                .fetchUsers(this.$store.getters['sessionStore/token']);

        this.users = users;

      } catch {
        this.$notify.error({
          title: 'Erreur',
          message: 'Il y a eu une erreur lors du chargement de vos utilisateurs. Veuillez réessayer',
        });
      }
      this.isLoading = false;
    }
}
