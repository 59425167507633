





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { UserInterface } from '@/modules/user/domain/UserInterface';

@Component
export default class UserTable extends Vue {
    @Prop() private readonly users!: any[];
    @Prop() private readonly isLoading!: boolean;

    private updateRouteFor(user: UserInterface) {
      this.$router.push(`/view-user/${user.id}`);

    }

    private deleteUser(user: UserInterface) {
      this.$confirm('Voulez vous vraiment supprimer cet utilisateur?', 'Attention').then(() => {
        this.$emit('delete', user);
      }).catch(() => null);
    }
}
